/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'material-icons/iconfont/material-icons.scss';

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import './app/app.scss';

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

// placed here instead of tutorial.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block !important;
}
.slideCard.swiper-slide.swiper-slide-active {
  text-align: inherit !important;
}

ion-card {
  box-shadow: var(--ion-card-shadow) 0px 4px 16px;
  color: var(--ion-text-color);
}

ion-toolbar{
  padding-right: var(--ion-safe-area-right, 0) !important;
  padding-left: var(--ion-safe-area-left, 0) !important;
}

*,
html {
  font-family: 'Open Sans', sans-serif;
}